import { useEffect, useRef, useState } from "react";
import "./style.scss"
import lottie from "lottie-web";
import animationData from "./animation/Main.json";

export const StopWatch = () => {
    const [displayTime, setDisplayTime] = useState("00:00:00");
    const [startTime, setStartTime] = useState(0);
    const [calcTime, setcalcTime] = useState(0);
    const [isRunning, setRunning] = useState(false);
    const [btnDisabled, setDisabled] = useState({ start: false, stop: true, reset: true });

    // 目標時間の管理と達成有無
    const [targetDisplayTime, setTarget] = useState("00:00:00");
    const [targetTime, setTargetTime] = useState(0);
    const [isGoal, setGoal] = useState(false);

    // 固定表示のAnimation
    const container = useRef(null);
    const animationName = "Congulaturation";
    const [isAnimationRunning, setAnimationRunning] = useState(false);
    const [animationStyle, setAnimationStyle] = useState("none");

    // ボタンをクリックした時の処理
    useEffect(() => {
        let timerInterval: number | undefined = undefined;
        // タイマーが動いている場合
        if (isRunning) {
            timerInterval = window.setInterval(() => {
                setcalcTime(Date.now() - startTime);
                // ミリ秒表示の場合、10
                // 秒表示の場合 1000.この値を変えることで表示状態を変化できる
            }, 100);
        }

        // クリーンアップ（タイマーをクリア）
        return () => {
            window.clearInterval(timerInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    // タイムスタンプを変換
    useEffect(() => {
        const currentTime = new Date(calcTime);
        const h = String(currentTime.getHours() - 9).padStart(2, "0");
        const m = String(currentTime.getMinutes()).padStart(2, "0");
        const s = String(currentTime.getSeconds()).padStart(2, "0");
        // ミリ秒表示
        // setDisplayTime(`${h}:${m}:${s}:${ms}`);
        setDisplayTime(`${h}:${m}:${s}`);
    }, [calcTime]);

    useEffect(() => {
        console.log("useEffectAnimation");
        if (container.current) {
            const anim = lottie.loadAnimation({
                container: container.current,
                renderer: "svg",
                loop: false,
                autoplay: false,
                animationData: animationData,
                name: animationName,
            });

            if (isAnimationRunning === true) {
                setAnimationStyle("flex");
                lottie.play();
            }

            anim.addEventListener("DOMLoaded", () => {
                console.log("DOM has been loaded!");
            });

            anim.addEventListener("complete", () => {
                console.log("Animation Complete");
                setAnimationStyle("none");
                setAnimationRunning(false);
            });

            console.log();
        }

        return () => {
            lottie.destroy(animationName);
        };
    }, [isAnimationRunning]);


    // Startボタンクリック（イベント）
    const onClickStart = () => {
        setStartTime(Date.now() - calcTime);
        setRunning(true);
        setGoal(false);
        setDisabled({ start: true, stop: false, reset: false })
    };

    // Pauseボタンクリック（イベント）
    const onClickPause = () => {
        setRunning(false);
        setDisabled({ start: false, stop: true, reset: false })
    };

    // Resetボタンクリック（イベント）
    const onClickReset = () => {
        setRunning(false);
        setDisplayTime("00:00:00");
        setcalcTime(0);
        setGoal(false);
        reflectInputTargetTime("00:00:00");
        setDisabled({ start: false, stop: true, reset: true })
    };

    // Doneボタンクリック（イベント）
    const onClickDone = () => {
        const set_false = false;
        const set_true = true;
        setRunning(set_false);
        setGoal(set_true);
        setDisabled({ start: set_false, stop: set_false, reset: set_false });
    }

    // 目標時間のユーザ入力（イベント）
    const reflectInputTargetTime = (props: string) => {
        setTarget(props);
        const conhour = props.toString().slice(0, 2);
        const conmin = props.toString().slice(3, 5);
        const consec = props.toString().slice(6, 8);
        console.log(conhour, ':', conmin, ':', consec);
        setTargetTime(parseInt(conhour) * 3600000 + parseInt(conmin) * 60000 + 0 );
    }

    // 達成時間と目標時間の比較
    const targetTimeConfirmation = () => {
        console.log(isGoal, calcTime, targetTime);
        if (calcTime < targetTime) {
            console.log("StartAnimation");
            setAnimationRunning(true);
        } else {
            console.log("NoAnimation");
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                <link rel="stylesheet" href="https://use.typekit.net/kym6rtb.css" />
            </header>

            <div>
                <h1 className='title'>TIME WISELY</h1>
                <h3 className='subtitle'>Hope everything goes well</h3>
            </div>

            <div id="goalAnimation" style={{display : animationStyle }} ref={container} />

            <div className="stopWatchArea">
                <time>{displayTime}</time>
            </div>

                {/*
                <div>
                    <button onClick={playbuttom}>Play</button>
                    <button onClick={pausebuttom}>Pause</button>
                    <button onClick={hiddenbuttom}>Hidden</button>
                </div>
                */}
                
                <div className="btnArea">
                    {/* 目標時間の入力 */}
                    <div id="targettime">
                    <p >TARGET TIME</p>
                    </div>
                    <input value={targetDisplayTime} disabled={isRunning}
                        onChange={(event) => { reflectInputTargetTime(event.target.value); }}
                        type='time'/>
                    <br></br>

                    {/* ストップウォッチの動作有無*/}
                    {isRunning ? (
                        <button onClick={onClickPause} disabled={btnDisabled.stop}>Pause</button>
                    ) : (
                        <button onClick={onClickStart} disabled={btnDisabled.start}>Start</button>
                    )}

                    {/* ストップウォッチの入力有無 */}
                    {targetDisplayTime === "00:00:00" ? (
                        <div>
                            <button onClick={onClickReset} disabled={btnDisabled.reset}>Reset</button>
                        </div>
                    ) : (
                        <div>
                            <button onClick={() => {
                                onClickDone();
                                targetTimeConfirmation();
                            }} disabled={btnDisabled.reset}>Done</button>
                            <button onClick={onClickReset} disabled={btnDisabled.reset}>Reset</button>
                        </div>
                    )}

                    {/* 達成条件の確認 */}
                    {isGoal && calcTime < targetTime &&
                        <div>
                            <p>Congratulations</p>
                        </div>
                    }
                    {isGoal && calcTime >= targetTime &&
                        <div>
                            <p>Opps</p>
                        </div>
                    }
                </div>
            </div>
    );
};